.font-montserrat-Regular {
  font-family: Montserrat-Regular;
}

.font-montserrat-bold {
  font-family: Montserrat-Bold;
}
.font-montserrat-semibold {
  font-family: Montserrat-SemiBold;
}

.font-montserrat-medium {
  font-family: Montserrat-Medium;
}

.font-sf-bold {
  font-family: SF-bold;
}

.font-sf-semibold {
  font-family: SF-semibold;
}

.font-sf-light {
  font-family: SF-light;
}

.font-sf-regular {
  font-family: SF-regular;
}

.font-sf-medium {
  font-family: SF-medium;
}

@font-face {
  font-family: 'SF-bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Bold'), url('../fonts/SF-Pro-Display-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'SF-semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Semibold'),
    url('../fonts/SF-Pro-Display-Semibold.otf') format('truetype');
}

@font-face {
  font-family: 'SF-regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Regular'),
    url('../fonts/SF-Pro-Display-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'SF-medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Medium'), url('../fonts/SF-Pro-Display-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'SF-light';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Light'), url('../fonts/SF-Pro-Display-Light.otf') format('truetype');
}

.booked-date {
  opacity: 1 !important;
  font-size: medium !important;
}

.bold-date {
  font-weight: bold !important;
  color: #478ae9 !important;
  background-color: #f6faff !important;
  border-radius: 20px !important;
  font-size: medium !important;
}

.flatpickr-disabled {
  background-color: transparent !important;
  font-weight: normal !important;
}

span.flatpickr-day,
span.flatpickr-day.prevMonthDay,
span.flatpickr-day.nextMonthDay {
  border-radius: 10 !important;
}

.current-date {
  opacity: 1 !important;
  font-size: medium !important;
}

.current-date::before {
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: rgb(92, 92, 94);
  bottom: 5px;
  height: 5px;
  content: '';
  width: 5px;
  left: 50%;
}

.prevMonthDay {
  opacity: 20%;
}

.nextMonthDay {
  opacity: 20%;
}

.selected {
  background-color: #2983ff !important;
  border: none !important;
  color: white !important;
  border-radius: 10px !important;
}

.overlay-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.flatpickr-weekday {
  text-transform: uppercase !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: black !important;
}

.overlay-block-blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 128, 0.1);
  overflow: hidden;
}

.full-screen {
  min-width: 100vh;
  min-height: 100vh;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.input {
  height: 45px !important;
}

.form-control {
  height: 45px !important;
}

.form-control:focus {
  background-color: #f5f6fa !important;
  box-shadow: none !important;
  border: 2px solid #042e60 !important;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  color: rgb(8, 1, 1);
}

// input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='email']:focus {
  box-shadow: none !important;
  border: 2px solid #042e60 !important;
  outline: 0 none;
  color: #042e60;
  background-color: #f5f6fa !important;
}

.card-rounded {
  border-radius: 25px;
}

.text-heading {
  color: #fd6163;
}

.btn {
  border: #dac4c4;
  border-radius: 10px;
  outline: none;
  padding: 10px;
}

.bg-dark-blue {
  background-color: #4065b4;
}

.bg-light-blue {
  background-color: #5698d4;
}

.modal-close-btn {
  position: absolute;
  right: 0;
  z-index: 1;
}

.container-top {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  min-height: 100vh;
}

.tr-bg-color {
  background-color: #eaf0f4;
}

.gray-bg-color {
  background-color: #f2f2f2;
}

.solid-blue {
  background-color: #5d9de7;
}

.light-mud {
  color: #a3a6b4;
}

.gray-icon {
  color: #bcbccb;
}

.dark-gray-icon {
  color: #7f7f7f;
}

.primary-color-icon {
  color: #042e60;
}

a,
a label {
  cursor: pointer;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #334752;
  border-color: #334752;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #334752;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #334752;
  border-color: #334752;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #334752;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.forward-button {
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: '20px';
  padding: 15px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  border: none;
}

.forward-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.forward-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.forward-button:hover span {
  padding-right: 25px;
}

.forward-button:hover span:after {
  opacity: 1;
  right: 0;
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.icon-zoom {
  padding: 20px;
  transition: margin-left 0.5s;
  height: 20px;
}

.icon-zoom:hover {
  margin-left: 15px;
}

.list-circle {
  list-style-type: circle !important;
}

ul.list-line {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding-left: 10px;
  }

  li::before {
    content: '-';
    padding-right: 5px;
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042e60;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042e60;
  cursor: pointer;
}

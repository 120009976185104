.opacity-87 {
  opacity: .875;
}

.opacity-90 {
  opacity: .9;
}

.c-trayvo {
  color: rgb(5,46,96);
}

.bg-trayvo {
  background-color: rgb(5,46,96);
}

.landing-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.backdrop {
  background-color:rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
}

.landing-transition {
  transition: all 0.2s ease-in-out;
}

.collapsible {
  overflow: hidden;

  &.collapsed {
    height: 0;

  }

  &.expanded {
    height: 600px;
  }
}

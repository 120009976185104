.body {
  font-family: 'SF-Regular';
}

.bg-home {
  background-image: url('../img/BgHome.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-auth {
  background-image: url('../img/bg-login.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-login-signup {
  background-image: url('../img/bg-login-signup.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-login-signup-blur {
  background-image: url('../img/bg-login-signup-blur.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-with-overlay {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../img/bg-login.png');
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

tr:hover {
  background-color: #f5f5f5;
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}

.trans {
  transition: width 2s;
}

.stripe-button {
  display: block;
  position: relative;
  padding: 0 12px;
  height: 30px;
  background: #1275ff;
  background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  font-size: 15px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 5px;
}

#mapBox div:first-child {
  position: relative !important;
}

@media only screen and (max-width: 768px) {
  .add-margin-on-small {
    margin-top: 30px;
  }

  .direction {
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  #month,
  #year,
  #day {
    width: 80px !important;
  }

  .custom-margin {
    margin-right: 0 !important;
  }
}

.slots-navigation {
  width: 307.875px;
  // margin: auto;
  button {
    svg {
      height: 14px;
      width: 14px;
    }
  }
}

.trainer-banner {
  z-index: 9;
  img {
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 915px) {
  .non-gird-small-view {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 1185px) {
  .hide-athlete-info {
    display: none;
  }

  .trainer-banner {
    left:23px;
    width: 5rem;
    height: 7rem;
    img {
      height: 60px !important;
      width: 60px !important;
    }
    small {
      margin-top: 2px !important;
      font-size: 12px !important;
    }
  }

  .change-direction {
    flex-direction: column;
  }

  .margin-left-0 {
    margin-left: 0px;
    margin-top: 5px;
  }

  .show-athlete-info {
    display: flex;
  }
}

@media only screen and (min-width: 1185px) {
  .show-athlete-info {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .direction-change {
    flex-direction: column !important;
    align-items: flex-start;
  }

  .direction-change-margin {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px !important;
  }

  .hide-dot {
    display: none;
  }

  .text-direction {
    text-align: left !important;
  }
}

.direction {
  flex-direction: column;
  display: flex !important;
}

.custom-margin {
  margin-right: 15rem;
}

.radius {
  border-radius: 1rem;
  border-width: 0.1px;
}

.train-size {
  width: 200px;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  // justify-content: center;

  .card-wrapper {
    display: inline-block;

    .card-text {
      position: relative;
      height: 100%;
      white-space: normal !important;
    }
  }
}

.search-location-input {
  display: flex;
}

.gray-border-right {
  border-right: 2px solid #d8d8d8;
}

.light-border-right {
  border-right: 1px solid #707070;
}

.search-location-input input {
  height: 45px;
  width: 100%;
  padding: 8px;
  border: none;
}

.search-location-input input:focus {
  outline: none;
}

#picker {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .shadow {
        box-shadow: none;
      }
    }
  }
}

.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 20px solid #c7a732;
  border-right: 20px solid transparent;
}

.white-shadow {
  -webkit-box-shadow: 14px 26px 66px -11px rgba(255, 255, 255, 0.74);
  -moz-box-shadow: 14px 26px 66px -11px rgba(255, 255, 255, 0.74);
  box-shadow: 14px 26px 26px -20px rgba(255, 255, 255, 0.74);
}

.gradient {
  background-image: linear-gradient(135deg, #4c62a0 35%, #90a3c2 100%);
}

.training-card-height {
  //min-height: 260px;
  //max-height: 280px;
  overflow: hidden;
}

.min-card-width {
  // width: 290px;
}

@media screen and (max-width: 750px) {
  .min-card-width {
    width: 100%;
  }
  .trainer-banner-info {
    padding-left: 0;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .trainer-banner-info {
    padding-left: 15px;
  }
}

.fa {
  padding: 10px;
  font-size: 17px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border-color: white;
  border-width: 1px;
  margin-left: 2px;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Facebook */
.fa-facebook {
  color: #042e60;
  width: 15px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 3px;
}

/* Twitter */
.fa-twitter {
  color: #042e60;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-instagram {
  color: #042e60;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-link {
  color: #042e60;
  -webkit-transform: rotateX(180deg);
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-tiktok {
  color: #042e60;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 7px;
}

.fa-tiktok {
  color: #042e60;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
}

#month {
  height: 45px !important;
  width: 100%;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 7px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

#day {
  height: 45px !important;
  width: 100%;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 7px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

#year {
  height: 45px !important;
  width: 100%;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 7px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

.flatpickr-calendar {
  box-shadow: none !important;
  font-size: 11px !important;
}

.flatpickr-disabled {
  color: rgba(64, 72, 72, 0.3) !important;
}

.flatpickr-day {
  display: inline-block;
  margin: 1px !important;
  max-height: 38px !important;
  max-width: 38px !important;

  line-height: 38px !important;
  color: rgba(86, 86, 86, 1) !important;
}

.flatpickr-day.selected {
  border-radius: 50% !important;
  background-color: rgb(5, 46, 96) !important;
  color: rgb(255, 255, 255) !important;
}

.flatpickr-day.today {
  border-radius: 50% !important;
}

.flatpickr-day:not(.flatpickr-disabled, .selected) {
  border-radius: 50% !important;
  background-color: rgba(5, 46, 96, .06) !important;
  color: rgb(5, 46, 96) !important;
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  opacity: .125 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.flatpickr-current-month {
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: 'SF-Regular';
}

span.flatpickr-day {
  border: 0px !important;
}

.toast-info-container {
  color: #042e60 !important;
  border-radius: 8px !important;
  font-size: medium;
  background: #ffffff !important;
  border: 4px solid #cf2831 !important;
  text-align: center;
}

.border-dots {
  // border-style: dotted;
  border-radius: 1;
  border-width: 1px;
  border-color: #a9a9a9;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
}

.max-90-vh {
  max-height: 90vh;
}

.max-70-vh {
  max-height: 70vh;
}

.line-connector {
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #d7dae2;
}

.css-guy9c2-control {
  box-shadow: none !important;
  border-width: 1px !important;
}

.css-12li2hw-control,
.css-19d14i8-control {
  box-shadow: none !important;
  border: 1px solid #d7dae2 !important;
  height: 45px;
}

.css-1pahdxg-control,
.css-yk16xz-control {
  height: 45px;
  border: 1px solid #d7dae2 !important;
}

.css-12li2hw-control {
  border: 2px solid #042e60 !important;
}

.css-a0v4lp-control {
  box-shadow: none !important;
  border-color: #234672 !important;
  border-width: 1px !important;
}

.css-12li2hw-control {
  border-color: #042e60 !important;
}

.rsd_month-container {
  width: 31% !important;
  margin-right: 3% !important;
}

.rsd_day-container {
  width: 31% !important;
  margin-right: 3% !important;
}

.rsd_year-container {
  width: 31% !important;
}

.cur-year {
  pointer-events: none !important;
  font-size: medium !important;
}
.arrowUp {
  display: none !important;
}

.arrowDown {
  display: none !important;
}

.cur-month {
  font-size: medium !important;
}

.Toastify__toast--success {
  color: #042e60 !important;
  border-radius: 8px !important;
  font-size: medium;
  background: #ffffff !important;
  border: 4px solid #2ecf28 !important;
  text-align: center;
}

.Toastify__toast--warning {
  color: #042e60 !important;
  border-radius: 8px !important;
  font-size: medium;
  background: #ffffff !important;
  border: 4px solid #cf2831 !important;
  text-align: center;
}

@media (min-width: 768px) {
  .md\:border-dots {
    // border-style: dotted;
    border-radius: 1;
    border-width: 1px;
    border-color: #a9a9a9;
    border-top-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control {
  height: calc(1.5em + 0.75rem + 9px) !important;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5 !important;
}
